import * as React from "react";
// import { Link } from "gatsby";
import NavItem from "./NavItem";

const NavigationSideBar = (props) => {
  const getSidebarToggle1 = () => {
    props?.sidebarToggle();
  };

  return (
    <nav className={`sidebar ${props?.showSidebar ? "active" : ""}`}>
      {menus.map((item, index) => {
        return (
          <NavItem
            key={`${index}`}
            item={item}
            sidebarToggle={getSidebarToggle1}
          />
        );
      })}
    </nav>
  );
};

const menus = [
  {
    link: "/app/builds",
    title: "Application Tests",
    icon: "fa fa-list",
    page: "builds",
  },
  {
    link: "/app",
    title: "Dashboard",
    icon: "fa fa-tachometer",
    page: "dashboard",
  },
  {
    link: "/app/products",
    title: "Products",
    icon: "fa fa-cubes",
    page: "products",
  },
  {
    link: "/app/core-engines",
    title: "Core Engines",
    icon: "fa fa-bolt",
    page: "coreengine",
  },
  {
    link: "/app/features",
    title: "Features",
    icon: "fa fa-anchor",
    page: "feature",
  },
  {
    link: "/app/users",
    title: "Users",
    icon: "fa fa-users",
    page: "user",
  },
  {
    link: "/documentation",
    title: "Documentation",
    icon: "fa fa-file",
    page: "document",
  },
];

export default NavigationSideBar;
